import React, { Dispatch, useEffect } from 'react'
import classNames from 'classnames'

import type { IAction } from 'app/store/model/action-types'
import {
  setIsAffirmationModal,
  setUserAccepted,
  setUserInfo,
} from 'app/store/actions'
import type { IUser } from 'entities/user/model/user'
import { useForm } from 'shared/lib/react-hook-form'
import { yupResolver } from 'shared/lib/yup-resolver'
import { Button } from 'shared/ui/button'

import type { IFormInputs } from '../model/types/IFormInputs'
import { sendUserData } from '../api/sendUserData'

import styles from './Form.module.scss'
import { useLocation, useNavigate } from 'react-router-dom'
import { ROUTES } from 'shared/config/routes'
import { getApiFormData } from 'features/affirmation/lib/helpers/getApiFormData'
import { getFormSchema } from 'features/affirmation/lib/helpers/getFormSchema'
import { FormProvider } from 'react-hook-form'
import { InputPhone } from 'features/affirmation/_ui/InputPhone/InputPhone'

interface IAffirmationFormProps {
  user: IUser
  dispatch: Dispatch<IAction>
  title?: string
  className?: string
  isAffirmationModal?: boolean
}

export function AffirmationForm(props: IAffirmationFormProps) {
  const { user, dispatch, title, isAffirmationModal, className } = props

  const navigate = useNavigate()
  const location = useLocation()

  const modalId = isAffirmationModal ? '-modal' : ''

  const apiFormData = getApiFormData('KZ')
  const formSchema = getFormSchema({
    phoneMatchesReg: apiFormData?.phoneMatchesReg,
    phoneMatchesErr: apiFormData?.phoneMatchesErr,
  })
  const formMethods = useForm<IFormInputs>({
    defaultValues: {
      phoneNumber: '',
      isPolitics: user.accepted.isPolitics ?? true,
      isAge: user.accepted.isAge ?? true,
      country: user.api.country,
      ip: user.api.ip,
      external_id: user.searchParams.external_id,
      metric: user.searchParams.metric,
      gaid: user.searchParams.gaid,
      sub1: user.searchParams.sub1,
      sub2: user.searchParams.sub2,
      sub3: user.searchParams.sub3,
      sub4: user.searchParams.sub4,
      sub5: user.searchParams.sub5,
      sub6: user.searchParams.sub6,
      sub7: user.searchParams.sub7,
      apps_id: user.searchParams.apps_id,
      devKey: user.searchParams.devKey,
      bundle: user.searchParams.bundle,
      status: user.searchParams.status,
    },
    // @ts-ignore
    resolver: yupResolver(formSchema),
  })
  const {
    setValue,
    handleSubmit,
    reset,
    register,
    formState: { errors, isSubmitSuccessful },
  } = formMethods

  const errFirstName = false
  const errLastName = false
  const errEmail = false

  const closeAffirmationModal = () => {
    dispatch(setIsAffirmationModal(false))
  }
  const onChecked = (e: any, id: any) => {
    const value = e?.target?.checked

    setValue(id, value)

    dispatch(setUserAccepted({ [id]: value }))
  }

  const onSubmit = handleSubmit(async (data: any) => {
    // query params
    setValue('external_id', user.searchParams.external_id)
    setValue('metric', user.searchParams.metric)
    setValue('gaid', user.searchParams.gaid)
    setValue('sub1', user.searchParams.sub1)
    setValue('sub2', user.searchParams.sub2)
    setValue('sub3', user.searchParams.sub3)
    setValue('sub4', user.searchParams.sub4)
    setValue('sub5', user.searchParams.sub5)
    setValue('sub6', user.searchParams.sub6)
    setValue('sub7', user.searchParams.sub7)
    setValue('apps_id', user.searchParams.apps_id)
    setValue('devKey', user.searchParams.devKey)
    setValue('bundle', user.searchParams.bundle)
    setValue('status', user.searchParams.status)

    setValue('isPolitics', user.accepted.isPolitics)
    setValue('isAge', user.accepted.isAge)

    await sendUserData({
      ...data,
      country: user.api.country,
      ip: user.api.ip,
      questions: [
        { ...user?.questions?.q1 },
        { ...user?.questions?.q2 },
        { ...user?.questions?.q3 },
        { ...user?.questions?.q4 },
      ],
    })

    dispatch(
      setUserInfo({
        firstName: data?.firstName,
        lastName: data?.lastName,
        phoneNumber: data?.phoneNumber,
        email: data?.email,
      }),
    )

    navigate({
      pathname: ROUTES.REGISTERED,
      search: location.search,
    })

    closeAffirmationModal()
  })

  useEffect(() => {
    if (isSubmitSuccessful) {
      dispatch(
        setUserAccepted({
          isPolitics: true,
          isAge: true,
        }),
      )

      reset()
    }
  }, [isSubmitSuccessful, reset])

  return (
    <FormProvider {...formMethods}>
      <form
        // @ts-ignore
        onSubmit={handleSubmit(onSubmit)}
        className={classNames(styles.form, className)}
      >
        <h3 className={styles.form__title}>{title}</h3>
        <div className={styles.form__names}>
          <div
            className={classNames(
              styles.form__input_block,
              errFirstName && styles.form__input_block_error,
            )}
          >
            <input
              {...register('firstName')}
              id={'firstName' + modalId}
              name={'firstName'}
              placeholder="Имя"
              type="text"
            />
            {errors?.firstName && (
              <p className={styles.errors}>{errors.firstName.message}</p>
            )}
          </div>

          <div
            className={classNames(
              styles.form__input_block,
              errLastName && styles.form__input_block_error,
            )}
          >
            <input
              {...register('lastName')}
              id={'lastName' + modalId}
              name={'lastName'}
              placeholder="Фамилия"
              type="text"
            />
            {errors?.lastName && (
              <p className={styles.errors}>{errors.lastName.message}</p>
            )}
          </div>
        </div>

        <InputPhone modalId={modalId} apiFormData={apiFormData} />

        <div
          className={classNames(
            styles.form__input_block,
            errEmail && styles.form__input_block_error,
          )}
        >
          <input
            {...register('email')}
            id={'email' + modalId}
            name={'email'}
            placeholder="Email"
            type="text"
          />
          {errors?.email && (
            <p className={styles.errors}>{errors.email.message}</p>
          )}
        </div>

        <>
          <input
            {...register('country')}
            id={'country' + modalId}
            name={'country'}
            type="hidden"
          />
          <input
            {...register('ip')}
            id={'ip' + modalId}
            name={'ip'}
            type="hidden"
          />
          <input
            {...register('external_id')}
            id={'external_id' + modalId}
            name={'external_id'}
            type="hidden"
          />
          <input
            {...register('metric')}
            id={'metric' + modalId}
            name={'metric'}
            type="hidden"
          />
          <input
            {...register('gaid')}
            id={'gaid' + modalId}
            name={'gaid'}
            type="hidden"
          />
          <input
            {...register('sub1')}
            id={'sub1' + modalId}
            name={'sub1'}
            type="hidden"
          />
          <input
            {...register('sub2')}
            id={'sub2' + modalId}
            name={'sub2'}
            type="hidden"
          />
          <input
            {...register('sub3')}
            id={'sub3' + modalId}
            name={'sub3'}
            type="hidden"
          />
          <input
            {...register('sub4')}
            id={'sub4' + modalId}
            name={'sub4'}
            type="hidden"
          />
          <input
            {...register('sub5')}
            id={'sub5' + modalId}
            name={'sub5'}
            type="hidden"
          />
          <input
            {...register('sub6')}
            id={'sub6' + modalId}
            name={'sub6'}
            type="hidden"
          />
          <input
            {...register('sub7')}
            id={'sub7' + modalId}
            name={'sub7'}
            type="hidden"
          />
          <input
            {...register('apps_id')}
            id={'apps_id' + modalId}
            name={'apps_id'}
            type="hidden"
          />
          <input
            {...register('devKey')}
            id={'devKey' + modalId}
            name={'devKey'}
            type="hidden"
          />
          <input
            {...register('bundle')}
            id={'bundle' + modalId}
            name={'bundle'}
            type="hidden"
          />
          <input
            {...register('status')}
            id={'status' + modalId}
            name={'status'}
            type="hidden"
          />
        </>

        <Button type="submit">Оставить заявку</Button>

        <label className={styles.checkSmallLabel} htmlFor="politics">
          <input
            {...register('isPolitics')}
            checked={isAffirmationModal && user.accepted.isPolitics}
            onChange={(e) => onChecked(e, 'isPolitics')}
            className={styles.checkSmall}
            type="checkbox"
          />
          <span className={styles.checkSmallLabelText}>
            Предоставляя свои персональные данные я даю согласие на обработку
            использование своих персональных данных
          </span>
        </label>
        <label className={styles.checkSmallLabel} htmlFor="age">
          <input
            {...register('isAge')}
            checked={isAffirmationModal && user.accepted.isAge}
            onChange={(e) => onChecked(e, 'isAge')}
            className={styles.checkSmall}
            type="checkbox"
          />
          <span className={styles.checkSmallLabelText}>
            Я подтверждаю, что мне больше 21-го года
          </span>
        </label>
      </form>
    </FormProvider>
  )
}
