import InputMask from 'react-input-mask'
import cl from 'classnames'

import type { ApiFormCountry } from '../../model/types/apiFormCountry'
import { phoneMaskChange } from '../../lib/helpers/phoneMaskChange'
import { ConnectForm } from '../ConnectForm/ConnectForm'
import cls from '../../form/Form.module.scss'

type InputPhoneProps = {
  modalId?: string
  apiFormData: ApiFormCountry
}

export const InputPhone = (props: InputPhoneProps) => {
  const { modalId, apiFormData } = props

  const inputName = 'phoneNumber'

  return (
    <ConnectForm>
      {({
        // @ts-ignore
        register,
        // @ts-ignore
        resetField,
        // @ts-ignore
        formState: { errors },
      }) => (
        <div
          className={cl(cls.form__input_block, cls['form__input_block--tel'])}
        >
          <InputMask
            {...register(inputName, { required: true })}
            className={cls.inputPhone}
            id={inputName + modalId}
            name={inputName}
            type="tel"
            placeholder={`${apiFormData.phoneTitle} Мобильный телефон`}
            mask={apiFormData.phoneMask}
            maskPlaceholder=""
            alwaysShowMask={false}
            beforeMaskedStateChange={phoneMaskChange({
              phoneTitle: apiFormData.phoneTitle,
              pastBugPhone: apiFormData.pastBugPhone,
              pastBug8Phone: apiFormData.pastBug8Phone,
            })}
          />
          <span className={cls.inputPhone__country}>
            <img
              src={apiFormData.icon}
              alt="country"
              className={cls.inputPhone__country__flag}
            />
          </span>
          {errors?.[inputName] && (
            <p className={cls.errors}>{errors?.[inputName]?.message}</p>
          )}
        </div>
      )}
    </ConnectForm>
  )
}
