import * as yup from 'yup'

import type { ApiFormCountry } from '../../model/types/apiFormCountry'

type GetFormSchema = Pick<ApiFormCountry, 'phoneMatchesReg' | 'phoneMatchesErr'>

export const getFormSchema = (props: GetFormSchema) => {
  const { phoneMatchesReg, phoneMatchesErr } = props

  return yup.object().shape({
    firstName: yup
      .string()
      .required('Укажите Имя')
      .min(2, 'Имя должно содержать более 2 букв')
      .max(40, 'Имя должно быть менее 40 букв')
      .test(
        'test-specialCharacter',
        'Имя не должно специальные символы',
        function (firstName) {
          const regex = new RegExp(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/)
          return regex.test(firstName) === true ? false : true
        },
      )
      .test(
        'test-numbers',
        'Имя не должно содержать цифры',
        function (firstName) {
          const regex = new RegExp('[0-9]')
          return regex.test(firstName) === true ? false : true
        },
      ),

    lastName: yup
      .string()
      .required('Укажите Фамилию')
      .min(2, 'Имя должно содержать более 2 букв')
      .max(40, 'Имя должно быть менее 40 букв')
      .test(
        'test-specialCharacter',
        'Имя не должно содержать специальные символы',
        function (lastName) {
          const regex = new RegExp(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/)
          return regex.test(lastName) === true ? false : true
        },
      )
      .test(
        'test-numbers',
        'Имя не должно содержать цифры',
        function (lastName) {
          const regex = new RegExp('[0-9]')
          return regex.test(lastName) === true ? false : true
        },
      ),
    phoneNumber: yup
      .string()
      .required('Укажите номер телефона')
      .matches(phoneMatchesReg, phoneMatchesErr),
    email: yup
      .string()
      .required('Укажите Email')
      .matches(
        /^(([^<>()\[\]\\.,;:\s@!"!#$%&'*+/=?^{|}~]+(\.[^<>()\[\]\\.,;:\s@!"!#$%&'*+/=?^{|}~]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z0-9\-]+\.)+[a-zA-Z]{2,}))$/,
        'Проверьте правильноcть ввода Email',
      ),
    isPolitics: yup.boolean().default(true),
    isAge: yup.boolean().default(true),
    country: yup.string(),
    ip: yup.string(),
    external_id: yup.string(),
    metric: yup.string(),
    gaid: yup.string(),
    sub1: yup.string(),
    sub2: yup.string(),
    sub3: yup.string(),
    sub4: yup.string(),
    sub5: yup.string(),
    sub6: yup.string(),
    sub7: yup.string(),
    apps_id: yup.string(),
    devKey: yup.string(),
    bundle: yup.string(),
    status: yup.string(),
    answers: yup.string(),
  })
}
